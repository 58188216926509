const ptBR= {
    login: {
      title: 'Fazer login',
      sign_in_btn: 'Fazer login'
    },
    bar: {
        logout: 'Sair',
        languages: 'Idiomas',
        settings: 'Abrir Configurações'
    },
    language: {
      name: 'Inglês (EUA)',
    },
    attributePage: {
        title: 'Alterações de atributos',
        viewChanges: 'Visualizar alterações',
        dateTime: 'Data',
        notificationType: 'Tipo de notificação',
        notificationValue: 'Valor da notificação',
        oldValue: 'Valor antigo',
        newValue: 'Valor novo',
        accept: 'Aceitar',
        reject: 'Rejeitar',
    },
    Home: 'Página principal',
    load: 'Executando carregamento inicial...',
    Requested: "Solicitado",
    Viewed: "Visualizado",
    Executing: "Em execução",
    Completed: "Concluído",
    Expired: "Expirado",
    Cancelled: "Cancelado",
    Error: "Erro",
    Rejected: "Rejeitado",
    error: 'Algo deu errado; tente novamente mais tarde.',
    displayname: 'Nome de exibição',
    givenname: 'Nome',
    surname: 'Sobrenome',
    email: 'E-mail',
    mail: 'E-mail',
    name: 'Nome',
    firstname: 'Nome',
    lastname: 'Sobrenome'
  };
  
  export default ptBR;
  