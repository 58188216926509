const svSE= {
    login: {
      title: 'Logga in',
      sign_in_btn: 'Logga in'
    },
    bar: {
        logout: 'Logga ut',
        languages: 'Språk',
        settings: 'Öppna Inställningar'
    },
    language: {
      name: 'Svenska (SE)',
    },
    attributePage: {
        title: 'Ändringar av egenskaper',
        viewChanges: 'Se ändringar',
        dateTime: 'Datum',
        notificationType: 'Aviseringstyp',
        notificationValue: 'Aviseringsvärde',
        oldValue: 'Gammalt värde',
        newValue: 'Nytt värde',
        accept: 'Godkänn',
        reject: 'Avvisa',
    },
    Home: 'Startsida',
    load: 'Utför inledande laddning ...',
    Requested: "Begärd",
    Viewed: "Visad",
    Executing: "Körs",
    Completed: "Klar",
    Expired: "Inte längre giltig",
    Cancelled: "Avbruten",
    Error: "Fel",
    Rejected: "Avvisad",
    error: 'Ett fel uppstod. Försök igen senare.',
    displayname: 'Visa namn',
    givenname: 'namn',
    surname: 'Efternamn',
    email: 'E-post',
    mail: 'E-post',
    name: 'namn',
    firstname: 'namn',
    lastname: 'Efternamn'
  };
  
  export default svSE;
  