const frCA= {
    login: {
      title: 'Se connecter',
      sign_in_btn: 'Se connecter'
    },
    bar: {
        logout: 'Se déconnecter',
        languages: 'Langues',
        settings: 'Ouvrir les paramètres'
    },
    language: {
      name: 'Français (CA)',
    },
    attributePage: {
        title: 'Modifications d’attributs',
        viewChanges: 'Voir les modifications',
        dateTime: 'Date',
        notificationType: 'Type de notification',
        notificationValue: 'Valeur de la notification',
        oldValue: 'Ancienne valeur',
        newValue: 'Nouvelle valeur',
        accept: 'Accepter',
        reject: 'Rejeter',
    },
    Home: 'Accueil',
    load: 'Exécution du chargement initial...',
    Requested: "Demandé",
    Viewed: "Consulté",
    Executing: "En cours d’exécution",
    Completed: "Complété",
    Expired: "Expiré",
    Cancelled: "Annulé",
    Error: "Erreur",
    Rejected: "Rejeté",
    error: 'Un problème est survenu; veuillez réessayer plus tard.',
    displayname: 'Nom affiché',
    givenname: 'Nom',
    surname: 'Nom de famille',
    email: 'E-mail',
    mail: 'E-mail',
    name: 'Nom',
    firstname: 'Nom',
    lastname: 'Nom de famille'
  };
  
  export default frCA;
  