const heIL= {
    login: {
      title: 'כניסה',
      sign_in_btn: 'כניסה'
    },
    bar: {
        logout: 'יציאה',
        languages: 'שפות',
        settings: 'פתח את ההגדרות'
    },
    language: {
      name: 'עברית (ישראל)',
    },
    attributePage: {
        title: 'שינויים בתכונות',
        viewChanges: 'צפייה בשינויים',
        dateTime: 'תאריך',
        notificationType: 'סוג ההודעה',
        notificationValue: 'ערך ההתראה',
        oldValue: 'ערך ישן',
        newValue: 'ערך חדש',
        accept: 'הסכם',
        reject: 'דחה',
    },
    Home: 'דף הבית',
    load: 'מבצע טעינה ראשונית...',
    Requested: "נדרש",
    Viewed: "נצפה",
    Executing: "מבצע",
    Completed: "הושלם",
    Expired: "פג תוקף",
    Cancelled: "בוטל",
    Error: "שגיאה",
    Rejected: "נדחה",
    error: 'משהו השתבש; נסה שוב מאוחר יותר.',
    displayname: 'שם תצוגה',
    givenname: 'שֵׁם',
    surname: 'שֵׁם מִשׁפָּחָה',
    email: 'אימייל',
    mail: 'אימייל',
    name: 'שֵׁם',
    firstname: 'שֵׁם',
    lastname: 'שֵׁם מִשׁפָּחָה'
  };
  
  export default heIL;
  